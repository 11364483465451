<script setup>
import { computed } from 'vue';
import { getDataObjectById } from 'o365-dataobject';
import { modalInitialMatrix, modalFinalMatrix } from 'sja.vue.libraries.modules.utilities.js';
import ActionsTabs from 'sja.vue.libraries.components.ActionsTabs.vue';

const props = defineProps({
    itemID: Number,
    taskRow: Object,
    sjaID: Number,
    readOnly: Boolean,
    participants: Boolean
});

let dsHazards = getDataObjectById(`dsTasksHazards_${props.sjaID}`)

const hazardData = computed(() => {
    return dsHazards.data.filter(item => item.SJATask_ID == props.taskRow.ID);
});

function openModal(hazard, modaltype) {
    const dsHazardsModalInitial = getDataObjectById(`dsTasksHazardsModalInitial`);
    const dsHazardsModalFinal = getDataObjectById(`dsTasksHazardsModalFinal`);
    dsHazardsModalInitial.recordSource.whereClause = `ID = ${hazard.ID}`
    dsHazardsModalFinal.recordSource.whereClause = `ID = ${hazard.ID}`
    if (modaltype === "Initial") {
        dsHazardsModalInitial.load().then(() => modalInitialMatrix.value.show())
    }
    if (modaltype === "Final") {
        dsHazardsModalFinal.load().then(() => modalFinalMatrix.value.show())
    }
}

</script>

<template>
    <div class="card" style="border-radius: var(--bs-card-border-radius) var(--bs-card-border-radius) 0 var(--bs-card-border-radius); !important">
        <div class="m-1" v-for="(hazard, index) in hazardData">
            <div class="d-flex flex-nowrap row gx-0">
                <div class="col-auto d-flex flex-column mb-2" style="width:15%">
                    <div v-if="index == 0" class="d-flex w-100 ms-1 mb-1 fw-semibold align-items-center"
                        style="height:34px">
                        <span class="text-truncate" :title="$t('Name of the Hazard')">{{ $t('Hazard Name') }}</span>
                    </div>
                    <OContentEditable class="w-100 flex-grow-1 p-1 text-break"
                        :class="{ 'inner-table-editable': !props.readOnly, 'mt-1': index != 0 }"
                        :isEditable="!props.readOnly" v-model="hazard.Name" @blur="dsHazards.save()" />
                </div>
                <div class="col-auto d-flex flex-column ms-2 mb-2" style="width:15%">
                    <div v-if="index == 0" class="d-flex w-100 ms-1 mb-1 fw-semibold align-items-center"
                        style="height:34px">
                        <span class="text-truncate" :title="$t('Consequenses of the following Hazard')">{{ $t('Consequences') }}</span>
                    </div>
                    <OContentEditable class="p-1 text-break flex-grow-1"
                        :class="{ 'inner-table-editable': !props.readOnly, 'mt-1': index != 0 }"
                        :isEditable="!props.readOnly" v-model="hazard.Consequences" @blur="dsHazards.save()" />
                </div>
                <div class="col d-flex flex-column flex-nowrap">
                    <div class="d-flex flex-row flex-grow-1 row gx-0">
                        <div class="col-auto text-center" style="width:50px">
                            <div v-if="index == 0" class="d-flex fw-semibold align-items-center justify-content-center"
                                :title="$t('Initial risk assessment.')" style="width:50px; height:32px">
                                <span>{{ $t('I') }}</span>
                            </div>
                            <i :title="$t('Edit the initial risk assessment.') + '\n' + $t('Probability') + ': ' + (hazard.ProbabilityNameAndDesc ? hazard.ProbabilityNameAndDesc : ' ') + '\n' + $t('Consequence') + ': ' + (hazard.ConsequenceNameAndDesc ? hazard.ConsequenceNameAndDesc : ' ')"
                                type="button"
                                :style="{ 'color': hazard.Color, 'cursor': props.readOnly ? 'default' : 'pointer' }"
                                class="fs-5 bi bi-square-fill"
                                @click="!props.readOnly ? openModal(hazard, 'Initial') : ''"></i>
                        </div>
                        <div class="col d-flex flex-nowrap flex-column">
                            <div v-if="index == 0" class="d-flex ms-1 mb-1 fw-semibold align-items-center"
                                style="height:34px">
                                <span class="text-truncate" :title="$t('New and existing actions')">{{ $t('New and existing actions') }}</span>
                            </div>
                            <div class="position-relative ">
                            <ActionsTabs :readOnly="props.readOnly" :participants="props.participants" :hazardRow="hazard" :sjaID="props.sjaID" :itemID="props.itemID"></ActionsTabs>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="d-flex flex-row row gx-0">
                        <div class="col-auto text-center align-top" style="width:50px">
                            <div v-if="index == 0" class="d-flex fw-semibold align-items-center justify-content-center"
                                :title="$t('Final risk assessment.')" style="width:50px; height:32px">
                                <span>{{ $t('F') }}</span>
                            </div>
                            <i :title="$t('Edit the final risk assessment.') + '\n' + $t('Probability') + ': ' + (hazard.FinalProbabilityNameAndDesc ? hazard.FinalProbabilityNameAndDesc : ' ') + '\n' + $t('Consequence') + ': ' + (hazard.FinalConsequenceNameAndDesc ? hazard.FinalConsequenceNameAndDesc : ' ')"
                                type="button"
                                :style="{ 'color': hazard.FinalColor, 'cursor': props.readOnly ? 'default' : 'pointer' }"
                                class="fs-5 bi bi-square-fill"
                                @click="!props.readOnly ? openModal(hazard, 'Final') : ''"></i>
                        </div>
                        <div class="col text-center me-2" style="width:15px">
                            <div v-if="index == 0" style="width:15px; height:32px"></div>
                            <OActionDelete v-if="!props.readOnly" :dataObject="dsHazards" :row="hazard"
                                class="btn btn-sm btn-link p-0" :title="$t('Delete hazard.')"
                                style="-bs-btn-line-height: 1;">
                                <i class="text-black bi bi-x-lg"></i>
                            </OActionDelete>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.inner-table-editable {
    border: 1.5px solid var(--o365-grid-border) !important;
    border-radius: var(--bs-border-radius) !important;
}

.inner-table-editable:focus,
.inner-table-editable:hover {
    outline: 0;
    border: 1.5px solid rgba(70, 130, 180, .25) !important;
    box-shadow: 0px 0px 0px 2px rgba(70, 130, 180, .25) !important;
}
</style>